<template>
  <div>
    <table-page :config="config" />
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { reservationType } from '@/utils/public';

export default {
  name: "serviceItems",
  components: {
    TablePage
  },
  data() {
    return {
      config: {
        /* 表格是否可多选 */
        dataUrl: '/benefit/getList?orgShopId=' + this.$route.query.id,
        belongTo: '当前机构：' + this.$route.query.other,
        filter: {
          initUrl: '/clientUser/serviceProject/add/get?id=' + this.$route.query.id,
          controls: []
        },
        columns: [
          {
            key: 'benefitType',
            title: '福利类型',
            width: '150px',
          },
          {
            key: 'briefIntroduction',
            title: '说明',
            width: '300px',
            ellipsis: true
          },
          {
            key: 'sort',
            title: '页面排序',
            width: '150px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '80px' : '80px'
          }

        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        checkbox: true,
        actions: [
          {
            key: 'edit',
            text: '新建预约福利',
            type: 'form',
            permission: '/benefit/insert',
            config: {
              color: 'primary',
              title: '新建预约福利',
              submitUrl: "/benefit/insert",
              submitText: '保存',
              controls: [
              {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'benefitType',
                  label: '福利类型',
                  required: true,
                  type: 'select',
                  required: true,
                  config: {
                    options: reservationType
                  }
                },
                {
                  key: 'briefIntroduction',
                  label: '简介',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 15, message: '请尽量精简内容，输入15字以内' }
                    ]
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                }
                
              ]
            }
          },
          {
            key: 'edit',
            text: '编辑福利介绍',
            type: 'form',
            permission: '/benefit/editBenefitPicture',
            config: {
              color: 'primary',
              title: '编辑福利介绍',
              initUrl: '/benefit/getBenefitPicture?orgShopId=' + this.$route.query.id,
              submitUrl: "/benefit/editBenefitPicture",
              submitText: '保存',
              controls: [
              {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'picture',
                  label: '图片',
                  // required: true,
                  tips: "可以添加组合多段图片，App上将按顺序展示为一整套图文信息。图片推荐宽度750px，单张图片请控制在5M以内。",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/serviceProject/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024000 * 5
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'edit',
            text: '删除',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/benefit/delete',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条预约福利，是否全部删除? `
            },
            config: {
              title: '删除成功',
              submitUrl: '/benefit/delete',
              submitText: '确认',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
              ]
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/benefit/edit',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/benefit/get",
              submitUrl: "/benefit/edit",
              submitText: '保存',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'benefitType',
                  label: '福利类型',
                  required: true,
                  type: 'select',
                  required: true,
                  config: {
                    options: reservationType
                  }
                },
                {
                  key: 'briefIntroduction',
                  label: '简介',
                  type: 'text',
                  config: {
                    rules: [
                      { min: 1, max: 15, message: '请尽量精简内容，输入15字以内' }
                    ]
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                }
              ]
            }
          }
        ]
      }
    }
  },
  async mounted() {
  },
  onUnmounted() {

  },
  computed: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss"></style>